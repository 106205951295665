import React, { useContext } from "react"
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom"
import { ConfigProvider } from "antd"
import "antd/dist/reset.css"
import { AuthProvider, AuthContext } from "./Contexts/AuthContext"
import BaseTemplate from "./Templates/BaseNavigation"
import LoginPage from "./Components/LoginPage"
import PosturasScreen from "./Components/PosturasCasetas"
import Usuarios from "./Components/Usuarios"
import ProductScreen from "./Components/Productos"
import { ReporteScreen } from "./Components/Reporte"
import { GeneralContext, GeneralProvider } from "./Contexts/GeneralContext"
// import './App.css';

const PrivateRoute: React.FC<any> = ({ children }) => {
  const { user } = useContext(AuthContext)

  if (!user) {
    return <Navigate to="/login" replace />
  }

  return children
}

const LogoutRoute: React.FC<any> = ({ children }) => {
  const { user, logout } = useContext(AuthContext)

  if (user) {
    logout()
  }

  return <Navigate to="/login" replace />
}

function App() {
  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#FDCB6E",
            // colorSuccess: "#CDE3D5",
            // colorError: "#EF476F",
            // colorText: "#333333",
            // colorBgBase: "#F8F4E5",
          },
          components: {
            Layout: {
              colorBgHeader: "#F8F4E5",
            },
          },
        }}
      >
        <AuthProvider>
          <GeneralProvider>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutRoute />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <BaseTemplate>
                      <Usuarios />
                    </BaseTemplate>
                  </PrivateRoute>
                }
              />
              <Route
                path="/posturas"
                element={
                  <PrivateRoute>
                    <BaseTemplate>
                      <PosturasScreen />
                    </BaseTemplate>
                  </PrivateRoute>
                }
              />
              <Route
                path="/productos"
                element={
                  <PrivateRoute>
                    <BaseTemplate>
                      <ProductScreen />
                    </BaseTemplate>
                  </PrivateRoute>
                }
              />
              <Route
                path="/reporte"
                element={
                  <PrivateRoute>
                    <BaseTemplate>
                      <ReporteScreen />
                    </BaseTemplate>
                  </PrivateRoute>
                }
              />
            </Routes>
          </GeneralProvider>
        </AuthProvider>
      </ConfigProvider>
    </Router>
  )
}

export default App
