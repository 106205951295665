// File: Login.tsx

import React, { useState, useContext, useEffect } from "react"
import { Form, Input, Button, message } from "antd"
import { AuthContext } from "../Contexts/AuthContext"

import { login } from "../Services/apiService"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

const Login: React.FC = () => {
  const { login: loginCtx, user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (user !== null) {
      navigate("/")
    }
  }, [user])

  const handleLogin = async (values: any) => {
    console.log(values)
    const { email, password } = values

    try {
      setLoading(true)
      const token = await login(email, password)
      await loginCtx(token)

      console.log("login correcto")
    } catch (error) {
      message.error(
        "usuario/contraseña incorrectos, favor de volver a intentar"
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form onFinish={handleLogin}>
      <LoginPageContainer>
        <LoginFormContainer>
          <LoginFormTitle>Login</LoginFormTitle>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Favor de ingresar su correo" }]}
          >
            <LoginFormInput placeholder="Username" name="email" type="email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Favor de ingresar su password." },
            ]}
          >
            <LoginFormInput
              placeholder="Password"
              type="password"
              name="password"
            />
          </Form.Item>
          <LoginFormButton type="primary" htmlType="submit">
            Log In
          </LoginFormButton>
        </LoginFormContainer>
      </LoginPageContainer>
    </Form>
  )
}

export default Login

const LoginPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const LoginFormContainer = styled.div`
  width: 300px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`

const LoginFormTitle = styled.h2`
  text-align: center;
`

const LoginFormInput = styled(Input)`
  margin-bottom: 16px;
`

const LoginFormButton = styled(Button)`
  width: 100%;
`
