import { getWithToken, postWithToken } from "./apiService"

export const TIPO_PRODUCTO = [
  "Limpio",
  "Debil",
  "Sucio",
  "Roto",
  "Rescatado",
  "Alimento",
]

export interface Producto {
  id: string
  nombre: string
  tipo_producto: number
  conversion_cajas: number
  conversion_fillers: number
  orden_captura: number
}

export const ProductosActions = (token: string | null) => {
  return {
    getProductos: async () => {
      try {
        const response = await getWithToken(`productos/listar`, token)
        return response
      } catch (error) {
        console.error("Error fetching productos:", error)
        throw error
      }
    },
    addProducto: async (producto: Producto) => {
      try {
        const response = await postWithToken(`productos/agregar`, token, {
          productos: [
            {
              nombre: producto.nombre,
              tipo_producto: producto.tipo_producto,
              conversion_cajas: producto.conversion_cajas,
              conversion_fillers: producto.conversion_fillers,
              orden_captura: producto.orden_captura,
            },
          ],
        })
        return response
      } catch (error) {
        console.error("Error adding productos:", error)
        throw error
      }
    },
    deleteProducto: async (id: string) => {
      try {
        const response = await postWithToken(`productos/borrar`, token, {
          productos: [{ id: id }],
        })
        return response
      } catch (error) {
        console.error("Error deleting productos:", error)
        throw error
      }
    },
    updateProducto: async (producto: Producto) => {
      try {
        const response = await postWithToken(`productos/actualizar`, token, {
          productos: [producto],
        })
        return response
      } catch (error) {
        console.error("Error updating producto:", error)
        throw error
      }
    },
  }
}
