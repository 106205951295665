import styled from "styled-components"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LogoWrapper = styled.div`
  width: 64px;
  height: 64px;
  width: 100%;
  margin-bottom: 20px;
`

export const CollapseWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 8px;
`

export const AvatarWrapper = styled.div`
  margin-left: auto;
`

export const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-right: 20px;
`
