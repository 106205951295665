import { getWithToken, postWithToken } from "./apiService"
import { Buffer } from "buffer"

export interface usuarioBackend {
  id?: string
  usuario: string
  password?: string
  nombre: string
  permisos: string
}

export const UsuariosActions = (token: string | null) => {
  return {
    getUsuarios: async () => {
      try {
        const response = await getWithToken(`usuarios/listar`, token)

        const cleanResponse = response.map((usuario: usuarioBackend) => {
          console.log(
            "Usuarios:",
            usuario,
            usuario.permisos,
            Buffer.from(usuario.permisos, "base64").toString()
          )
          return {
            ...usuario,
            permisos: {
              posturas: usuario.permisos.length
                ? JSON.parse(
                    Buffer.from(usuario.permisos, "base64").toString("ascii")
                  )
                : [],
            },
          }
        })
        console.log("Usuarios:", cleanResponse)
        return cleanResponse
      } catch (error) {
        console.error("Error fetching usuarios:", error)
        throw error
      }
    },
    addUsuario: async (usuario: usuarioBackend) => {
      try {
        const usuarioEnviar = {
          nombre: usuario.nombre,
          usuario: usuario.usuario,
          password: usuario.password,
          permisos: Buffer.from(usuario.permisos).toString("base64"),
        }

        const response = await postWithToken(`usuarios/agregar`, token, {
          usuarios: [usuarioEnviar],
        })
        return response
      } catch (error) {
        console.error("Error adding usuarios:", error)
        throw error
      }
    },
    deleteUsuario: async (id: string) => {
      try {
        const response = await postWithToken(`usuarios/borrar`, token, {
          usuarios: [{ id: id }],
        })
        return response
      } catch (error) {
        console.error("Error deleting usuarios:", error)
        throw error
      }
    },
    updateUsuario: async (usuario: usuarioBackend) => {
      try {
        const usuarioEnviar = {
          ...usuario,
          permisos: Buffer.from(usuario.permisos).toString("base64"),
        }

        const response = await postWithToken(`usuarios/actualizar`, token, {
          usuarios: [usuarioEnviar],
        })
        return response
      } catch (error) {
        console.error("Error updating usuarios:", error)
        throw error
      }
    },
  }
}
