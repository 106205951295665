import { postWithToken } from "./apiService"

export const CasetasActions = (token: string | null) => {
  return {
    getCasetas: async (idPostura: string) => {
      if (idPostura === "") return Promise.resolve([])
      try {
        const response = await postWithToken(`casetas/listar`, token, {
          filtro: { id_postura: idPostura },
        })
        return response
      } catch (error) {
        console.error("Error fetching casetas:", error)
        throw error
      }
    },
    addCaseta: async (
      idPostura: string,
      nombre: string,
      num_caseta: number
    ) => {
      try {
        const response = await postWithToken(`casetas/agregar`, token, {
          casetas: [
            {
              nombre: nombre,
              id_postura: idPostura,
              num_caseta: num_caseta.toString(),
            },
          ],
        })
        return response
      } catch (error) {
        console.error("Error adding caseta:", error)
        throw error
      }
    },
    deleteCaseta: async (id: string) => {
      try {
        const response = await postWithToken(`casetas/borrar`, token, {
          casetas: [{ id: id }],
        })
        return response
      } catch (error) {
        console.error("Error deleting caseta:", error)
        throw error
      }
    },
    updateCaseta: async (
      id: string,
      id_postura: string,
      nombre: string,
      num_caseta: number
    ) => {
      try {
        const response = await postWithToken(`casetas/actualizar`, token, {
          casetas: [{ id, id_postura, nombre, num_caseta }],
        })
        return response
      } catch (error) {
        console.error("Error updating caseta:", error)
        throw error
      }
    },
  }
}
