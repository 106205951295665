import { getWithToken, postWithToken } from "./apiService"

export const ProduccionActions = (token: string | null) => {
  return {
    getReporte: async () => {
      try {
        const response = await getWithToken(`produccion/reporte`, token)
        return response
      } catch (error) {
        console.error("Error fetching reporte produccion:", error)
        throw error
      }
    },
    postReporte: async (fecini: string, fecfin: string) => {
      try {
        console.log("postReporte", {
          fecha_inicial: fecini,
          fecha_final: fecfin,
        })

        const response = await postWithToken(`produccion/reporte`, token, {
          fecha_inicial: fecini,
          fecha_final: fecfin,
        })
        return response
      } catch (error) {
        console.error("Error fetching reporte produccion:", error)
        throw error
      }
    },
  }
}
