import React, { useState, useEffect, useContext } from "react"
import { Table, Button, Input, Select, Modal, Form } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import {
  Producto,
  ProductosActions,
  TIPO_PRODUCTO,
} from "../Services/productos"
import { AuthContext } from "../Contexts/AuthContext"
import { Contenido } from "./Productos.styles"
import { useGeneralContext } from "../Contexts/GeneralContext"

const { Option } = Select

const ProductScreen: React.FC = () => {
  const { token } = useContext(AuthContext)
  const { setTitulo } = useGeneralContext()

  const [products, setProducts] = useState<Producto[]>([])
  const [editingId, setEditingId] = useState<string | null>(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [newProduct, setNewProduct] = useState<Producto>({
    id: "",
    nombre: "",
    tipo_producto: 0,
    conversion_cajas: 0,
    conversion_fillers: 0,
    orden_captura: 0,
  })

  const productosACtions = ProductosActions(token)

  useEffect(() => {
    setTitulo("Productos")
    // Fetch the product data from the backend
    fetchProducts()
  }, [])

  const fetchProducts = async () => {
    try {
      const data = await productosACtions.getProductos()
      setProducts(data)
    } catch (error) {
      console.error("Error fetching products:", error)
    }
  }

  const addProduct = async (product: Producto) => {
    try {
      await productosACtions.addProducto(product)
      fetchProducts()
    } catch (error) {
      console.error("Error adding product:", error)
    }
  }

  const updateProduct = async (updatedProduct: Producto) => {
    try {
      await productosACtions.updateProducto(updatedProduct)
      fetchProducts()
    } catch (error) {
      console.error("Error updating product:", error)
    }
  }

  const deleteProduct = async (id: string) => {
    try {
      await productosACtions.deleteProducto(id)
      fetchProducts()
    } catch (error) {
      console.error("Error deleting product:", error)
    }
  }

  const handleEdit = (id: string) => {
    setEditingId(id)
    setNewProduct(products.find((product) => product.id === id)!)
  }

  const handleSave = (id: string) => {
    if (newProduct) {
      updateProduct(newProduct)
    }

    setEditingId(null)
  }

  const handleCancel = () => {
    setEditingId(null)
  }

  const handleAddProduct = () => {
    setModalVisible(true)
  }

  const handleModalCancel = () => {
    setModalVisible(false)
    setNewProduct({
      id: "",
      nombre: "",
      tipo_producto: 0,
      conversion_cajas: 0,
      conversion_fillers: 0,
      orden_captura: 0,
    })
  }

  const handleModalOk = () => {
    addProduct(newProduct)
    setModalVisible(false)
    setNewProduct({
      id: "",
      nombre: "",
      tipo_producto: 0,
      conversion_cajas: 0,
      conversion_fillers: 0,
      orden_captura: 0,
    })
  }

  const handleChange = (key: string, value: any) => {
    const updatedProduct = {
      ...newProduct,
      [key]: value,
    }
    setNewProduct(updatedProduct)
  }

  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      render: (_: any, record: Producto) => (
        <span>
          {editingId === record?.id ? (
            <Input
              value={newProduct.nombre}
              onChange={(e) => handleChange("nombre", e.target.value)}
            />
          ) : (
            record?.nombre
          )}
        </span>
      ),
    },
    {
      title: "Orden para Captura",
      dataIndex: "orden_captura",
      key: "orden_captura",
      render: (_: any, record: Producto) => (
        <span>
          {editingId === record?.id ? (
            <Input
              value={newProduct.orden_captura}
              onChange={(e) =>
                handleChange("orden_captura", parseInt(e.target.value, 10))
              }
              type="number"
            />
          ) : (
            record?.orden_captura
          )}
        </span>
      ),
    },
    {
      title: "Tipo de Producto",
      dataIndex: "tipo_producto",
      key: "tipo_producto",
      render: (_: any, record: Producto) => (
        <span>
          {editingId === record?.id ? (
            <Select
              value={newProduct?.tipo_producto || 0}
              onChange={(value) => handleChange("tipo_producto", value)}
              style={{ width: "150px" }}
            >
              <Option value={0}>Limpio</Option>
              <Option value={1}>Debil</Option>
              <Option value={2}>Sucio</Option>
              <Option value={3}>Roto</Option>
              <Option value={4}>Rescatado</Option>
              <Option value={5}>Alimento</Option>
            </Select>
          ) : (
            TIPO_PRODUCTO[record?.tipo_producto || 0]
          )}
        </span>
      ),
    },
    {
      title: "Conversion Cajas",
      dataIndex: "conversion_cajas",
      key: "conversion_cajas",
      render: (_: any, record: Producto) => (
        <span>
          {editingId === record?.id ? (
            <Input
              value={newProduct.conversion_cajas}
              onChange={(e) =>
                handleChange("conversion_cajas", parseInt(e.target.value, 10))
              }
              type="number"
            />
          ) : (
            record?.conversion_cajas
          )}
        </span>
      ),
    },
    {
      title: "Conversion Fillers",
      dataIndex: "conversion_fillers",
      key: "conversion_fillers",
      render: (_: any, record: Producto) => (
        <span>
          {editingId === record?.id ? (
            <Input
              value={newProduct.conversion_fillers}
              onChange={(e) =>
                handleChange("conversion_fillers", parseInt(e.target.value, 10))
              }
              type="number"
            />
          ) : (
            record?.conversion_fillers
          )}
        </span>
      ),
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_: any, record: Producto) => (
        <span>
          {editingId === record?.id ? (
            <>
              <Button type="link" onClick={() => handleSave(record.id)}>
                Grabar
              </Button>
              <Button type="link" onClick={handleCancel}>
                Cancelar
              </Button>
            </>
          ) : (
            <>
              <Button type="link" onClick={() => handleEdit(record.id)}>
                Editar
              </Button>
              <Button type="link" onClick={() => deleteProduct(record.id)}>
                Borrar
              </Button>
            </>
          )}
        </span>
      ),
    },
  ]

  return (
    <Contenido>
      <Button type="primary" onClick={handleAddProduct} icon={<PlusOutlined />}>
        Agregar Producto
      </Button>
      <Table dataSource={products} columns={columns} rowKey="id" />

      <Modal
        title="Agregar Producto"
        open={modalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
      >
        <Form>
          <Form.Item label="Nombre">
            <Input
              value={newProduct.nombre}
              onChange={(e) => handleChange("nombre", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Orden para Captura">
            <Input
              value={newProduct.orden_captura}
              onChange={(e) =>
                handleChange("orden_captura", parseInt(e.target.value, 10))
              }
              type="number"
            />
          </Form.Item>
          <Form.Item label="Tipo de Producto">
            <Select
              value={newProduct.tipo_producto}
              onChange={(value) => handleChange("tipo_producto", value)}
            >
              <Option value={0}>Limpio</Option>
              <Option value={1}>Debil</Option>
              <Option value={2}>Sucio</Option>
              <Option value={3}>Roto</Option>
              <Option value={4}>Rescatado</Option>
              <Option value={5}>Alimento</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Conversion Cajas">
            <Input
              value={newProduct.conversion_cajas}
              onChange={(e) =>
                handleChange("conversion_cajas", parseInt(e.target.value, 10))
              }
              type="number"
            />
          </Form.Item>
          <Form.Item label="Conversion Fillers">
            <Input
              value={newProduct.conversion_fillers}
              onChange={(e) =>
                handleChange("conversion_fillers", parseInt(e.target.value, 10))
              }
              type="number"
            />
          </Form.Item>
        </Form>
      </Modal>
    </Contenido>
  )
}

export default ProductScreen
