// File: AuthContext.tsx

import React, { createContext, useEffect, useState } from "react"
import { getWithToken } from "../Services/apiService"

interface GeneralContextProps {
  titulo: string
  setTitulo: (titulo: string) => void
}

export const GeneralContext = createContext<GeneralContextProps>({
  titulo: "",
  setTitulo: () => Promise.resolve(),
})

interface GeneralProviderProps {
  children: any
}

export const GeneralProvider: React.FC<GeneralProviderProps> = ({
  children,
}) => {
  const [titulo, setTitulo] = useState<string>("")

  const generalContextValue: GeneralContextProps = {
    titulo,
    setTitulo,
  }

  return (
    <GeneralContext.Provider value={generalContextValue}>
      {children}
    </GeneralContext.Provider>
  )
}

export const useGeneralContext = () => React.useContext(GeneralContext)
