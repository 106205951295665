import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`

export const Contenido = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  gap: 16px;
  display: flex;
`
