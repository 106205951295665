import { getWithToken, postWithToken } from "./apiService"

export const PosturasActions = (token: string | null) => {
  return {
    getPosturas: async () => {
      try {
        const response = await getWithToken(`posturas/listar`, token)
        return response
      } catch (error) {
        console.error("Error fetching posturas:", error)
        throw error
      }
    },
    addPostura: async (nombre: string) => {
      try {
        const response = await postWithToken(`posturas/agregar`, token, {
          posturas: [{ nombre: nombre }],
        })
        return response
      } catch (error) {
        console.error("Error adding postura:", error)
        throw error
      }
    },
    deletePostura: async (id: string) => {
      try {
        const response = await postWithToken(`posturas/borrar`, token, {
          posturas: [{ id: id }],
        })
        return response
      } catch (error) {
        console.error("Error deleting postura:", error)
        throw error
      }
    },
    updatePostura: async (id: string, name: string) => {
      try {
        const response = await postWithToken(`posturas/actualizar`, token, {
          posturas: [{ id: id, nombre: name }],
        })
        return response
      } catch (error) {
        console.error("Error updating postura:", error)
        throw error
      }
    },
  }
}
