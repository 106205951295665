import React, { useContext, useEffect, useState } from "react"
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  Select,
  Modal,
  Checkbox,
  DatePicker,
  Col,
  Row,
  message,
} from "antd"
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons"
import { PosturasActions } from "../Services/posturas"
import { CasetasActions } from "../Services/casetas"
import { ParvadasActions } from "../Services/parvadas"
import { AuthContext } from "../Contexts/AuthContext"
import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import AdvancedFormat from "dayjs/plugin/advancedFormat"
import { Contenido, Wrapper } from "./Usuarios.styles"
import { useGeneralContext } from "../Contexts/GeneralContext"

export interface Postura {
  id: string
  nombre: string
}

export interface Caseta {
  id: string
  id_postura: string
  nombre: string
  num_caseta: number
}

export interface Parvadas {
  id: string
  id_postura: string
  lote: string
  fecha_inicio: string
  activo: number
}

const PosturasScreen: React.FC = () => {
  const { token } = useContext(AuthContext)
  const { setTitulo } = useGeneralContext()

  const [posturas, setPosturas] = useState<Postura[]>([])
  const [casetas, setCasetas] = useState<Caseta[]>([])
  const [parvadas, setParvadas] = useState<Parvadas[]>([])

  const [modalVisible, setModalVisible] = useState(false)
  const [casetasModalVisible, setCasetasModalVisible] = useState(false)
  const [parvadasModalVisible, setParvadasModalVisible] = useState(false)

  const [newPosturaName, setNewPosturaName] = useState("")

  const [newCasetaName, setNewCasetaName] = useState("")
  const [newCasetaNumCaseta, setNewCasetaNumCaseta] = useState(0)

  const [newParvadaLote, setNewParvadaLote] = useState("")
  const [newParvadaFechaInicio, setNewParvadaFechaInicio] = useState<Dayjs>(
    dayjs()
  )
  const [newParvadaActivo, setNewParvadaActivo] = useState(true)

  const [selectedPosturaId, setSelectedPosturaId] = useState("")
  const [showCasetas, setShowCasetas] = useState(true)

  const posturasActions = PosturasActions(token)
  const casetasActions = CasetasActions(token)
  const parvadasActions = ParvadasActions(token)

  useEffect(() => {
    setTitulo("Posturas")
    fetchPosturas()
  }, [])

  const fetchPosturas = async () => {
    try {
      const data = await posturasActions.getPosturas()
      setPosturas(data)

      if (data.length > 0) {
        setSelectedPosturaId(data[0].id)
        fetchCasetas()
      }
    } catch (error) {
      console.error("Error fetching posturas:", error)
    }
  }

  const fetchCasetas = async () => {
    try {
      const data = await casetasActions.getCasetas(selectedPosturaId)
      setCasetas(data)
    } catch (error) {
      console.error("Error fetching casetas:", error)
    }
  }

  const fetchParvadas = async () => {
    try {
      const data = await parvadasActions.getParvadas(selectedPosturaId)
      console.log("Parvadas", data)
      setParvadas(data)
    } catch (error) {
      console.error("Error fetching parvadas:", error)
    }
  }

  const handleAddPostura = async () => {
    try {
      await posturasActions.addPostura(newPosturaName)
      fetchPosturas()
      setModalVisible(false)
      setNewPosturaName("")
    } catch (error) {
      console.error("Error adding postura:", error)
    }
  }

  const handleAddCaseta = async () => {
    try {
      await casetasActions.addCaseta(
        selectedPosturaId,
        newCasetaName,
        newCasetaNumCaseta
      )
      fetchCasetas()
      setCasetasModalVisible(false)
      setNewCasetaName("")
      setNewCasetaNumCaseta(0)
    } catch (error) {
      console.error("Error adding caseta:", error)
    }
  }

  const handleAddParvada = async () => {
    try {
      await parvadasActions.addParvada(
        selectedPosturaId,
        newParvadaLote,
        newParvadaFechaInicio?.format("YYYYMMDD") || "",
        newParvadaActivo
      )
      fetchParvadas()
      setParvadasModalVisible(false)
      setNewParvadaLote("")
      setNewParvadaFechaInicio(dayjs())
      setNewParvadaActivo(false)
    } catch (error) {
      console.error("Error adding parvada:", error)
    }
  }

  const handleSavePostura = async (id: string, name: string) => {
    try {
      await posturasActions.updatePostura(id, name)
      fetchPosturas()
    } catch (error) {
      console.error("Error updating postura:", error)
    }
  }

  const handleSaveCaseta = async (
    id: string,
    idPostura: string,
    name: string,
    numcaseta: number
  ) => {
    try {
      await casetasActions.updateCaseta(id, idPostura, name, numcaseta)
      fetchCasetas()
    } catch (error) {
      console.error("Error updating caseta:", error)
    }
  }

  const handleSaveParvada = async (
    id: string,
    lote: number,
    fechaInicio: string,
    activo: number
  ) => {
    try {
      await parvadasActions.updateParvada(
        id,
        selectedPosturaId,
        lote,
        fechaInicio,
        activo === 1 ? true : false
      )
      fetchParvadas()
    } catch (error) {
      console.error("Error updating parvada:", error)
    }
  }

  const handleDeletePostura = async (id: string) => {
    try {
      await posturasActions.deletePostura(id)
      fetchPosturas()
    } catch (error: any) {
      console.error("Error deleting postura:", error)
      message.error(error.response?.data?.mensaje || "Error borrando postura")
    }
  }

  const handleDeleteCaseta = async (id: string) => {
    try {
      await casetasActions.deleteCaseta(id)
      fetchCasetas()
    } catch (error) {
      console.error("Error deleting caseta:", error)
    }
  }

  const handleDeleteParvada = async (id: string) => {
    try {
      await parvadasActions.deleteParvada(id)
      fetchParvadas()
    } catch (error) {
      console.error("Error deleting caseta:", error)
    }
  }

  const columns = [
    {
      title: "Postura",
      dataIndex: "name",
      key: "name",
      render: (_: any, record: Postura) => (
        <EditableCell
          value={record.nombre}
          onSave={(name: string) => handleSavePostura(record.id, name)}
        />
      ),
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: Postura) => (
        <Button
          type="link"
          onClick={() => handleDeletePostura(record.id)}
          icon={<DeleteOutlined />}
        />
      ),
    },
    {
      title: "Casetas",
      dataIndex: "casetas",
      key: "casetas",
      render: (_: any, record: Postura) => (
        <Button type="link" onClick={() => handleViewCasetas(record.id)}>
          Ver Casetas
        </Button>
      ),
    },
    {
      title: "Parvadas",
      dataIndex: "parvadas",
      key: "parvadas",
      render: (_: any, record: Postura) => (
        <Button type="link" onClick={() => handleViewParvadas(record.id)}>
          Ver Parvadas
        </Button>
      ),
    },
  ]

  const casetasColumns = [
    {
      title: "Caseta",
      dataIndex: "nombre",
      key: "nombre",
      render: (_: any, record: Caseta) => (
        <EditableCell
          value={record.nombre}
          onSave={(name: string) =>
            handleSaveCaseta(
              record.id,
              record.id_postura,
              name,
              record.num_caseta
            )
          }
        />
      ),
    },
    {
      title: "Numero Caseta",
      dataIndex: "num_caseta",
      key: "num_caseta",
      render: (_: any, record: Caseta) => (
        <EditableCell
          value={record.num_caseta}
          onSave={(numero: number) =>
            handleSaveCaseta(
              record.id,
              record.id_postura,
              record.nombre,
              numero
            )
          }
        />
      ),
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: Caseta) => (
        <Button
          type="link"
          onClick={() => handleDeleteCaseta(record.id)}
          icon={<DeleteOutlined />}
        />
      ),
    },
  ]

  const parvadasColumns = [
    {
      title: "Lote",
      dataIndex: "lote",
      key: "lote",
      render: (_: any, record: Parvadas) => (
        <EditableCell
          value={record.lote}
          onSave={(lote: string) =>
            handleSaveParvada(
              record.id,
              parseInt(lote),
              record.fecha_inicio,
              record.activo
            )
          }
        />
      ),
    },
    {
      title: "Fecha Inicio",
      dataIndex: "fecha_inicio",
      key: "fecha_inicio",
      render: (_: any, record: Parvadas) => (
        <EditableCellDate
          value={record.fecha_inicio}
          onSave={(fechaInicio: string) =>
            handleSaveParvada(
              record.id,
              parseInt(record.lote),
              fechaInicio,
              record.activo
            )
          }
          onRender={(fechaInicio: string) => {
            dayjs.extend(customParseFormat)
            dayjs.extend(AdvancedFormat)
            return dayjs(`${fechaInicio}`, "YYYYMMDD").format("DD/MM/YYYY")
          }}
        />
      ),
    },
    {
      title: "Activo",
      dataIndex: "activo",
      key: "activo",
      render: (_: any, record: Parvadas) => (
        <EditableCellBoolean
          value={record.activo}
          onSave={(activo: number) =>
            handleSaveParvada(
              record.id,
              parseInt(record.lote),
              record.fecha_inicio,
              activo
            )
          }
          onRender={(activo: number) => {
            return activo === 1 ? <CheckSquareOutlined /> : <></>
          }}
        />
      ),
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: Parvadas) => (
        <Button
          type="link"
          onClick={() => handleDeleteParvada(record.id)}
          icon={<DeleteOutlined />}
        />
      ),
    },
  ]

  const handleViewCasetas = (posturaId: string) => {
    console.log("handleViewCasetas", posturaId)
    setSelectedPosturaId(posturaId)
    setShowCasetas(true)
  }

  const handleViewParvadas = (posturaId: string) => {
    console.log("handleViewParvadas", posturaId)
    setSelectedPosturaId(posturaId)
    setShowCasetas(false)
  }

  useEffect(() => {
    fetchCasetas()
    fetchParvadas()
  }, [selectedPosturaId])

  const EditableCell: React.FC<any> = ({ value, onSave, onRender }) => {
    const [editing, setEditing] = useState(false)
    const [inputValue, setInputValue] = useState(value)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
    }

    const handleSaveClick = () => {
      onSave(inputValue)
      setEditing(false)
    }

    const handleEditClick = () => {
      setEditing(true)
    }

    const renderValue = () => {
      if (onRender) {
        return onRender(value)
      }
      return value
    }

    return (
      <div>
        {editing ? (
          <div>
            <Input value={inputValue} onChange={handleInputChange} />
            <Button type="primary" onClick={handleSaveClick}>
              Grabar
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <span style={{ flex: 1 }}>{renderValue()}</span>
            <Button
              type="link"
              onClick={handleEditClick}
              icon={<EditOutlined />}
            />
          </div>
        )}
      </div>
    )
  }

  const EditableCellBoolean: React.FC<any> = ({
    value,
    onSave,
    onRender,
  }: {
    value: number
    onSave: (activo: number) => void
    onRender: (activo: number) => JSX.Element
  }) => {
    const [editing, setEditing] = useState(false)
    const [inputValue, setInputValue] = useState(value)

    console.log("EditableCellBoolean", value, inputValue)

    const handleSaveClick = () => {
      onSave(inputValue)
      setEditing(false)
    }

    const handleEditClick = () => {
      setEditing(true)
    }

    const renderValue = () => {
      if (onRender) {
        return onRender(value)
      }
      return value
    }

    return (
      <div>
        {editing ? (
          <div>
            <Checkbox
              checked={inputValue === 1 ? true : false}
              onChange={(activo) => {
                setInputValue(activo.target.checked ? 1 : 0)
              }}
              style={{ marginRight: 10 }}
            />
            <Button type="primary" onClick={handleSaveClick}>
              Grabar
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <span style={{ flex: 1 }}>{renderValue()}</span>
            <Button
              type="link"
              onClick={handleEditClick}
              icon={<EditOutlined />}
            />
          </div>
        )}
      </div>
    )
  }

  const EditableCellDate: React.FC<any> = ({
    value,
    onSave,
    onRender,
  }: {
    value: string
    onSave: (fecha: string) => void
    onRender: (fecha: string) => JSX.Element
  }) => {
    const [editing, setEditing] = useState(false)
    const [inputValue, setInputValue] = useState(value)
    const [newParvadaFechaInicio, setNewParvadaFechaInicio] = useState<Dayjs>(
      dayjs()
    )

    console.log("EditableCellDate", value, inputValue, newParvadaFechaInicio)

    const handleSaveClick = () => {
      onSave(inputValue)
      setEditing(false)
    }

    const handleEditClick = () => {
      setEditing(true)
    }

    const renderValue = () => {
      if (onRender) {
        return onRender(value)
      }
      return value
    }

    useEffect(() => {
      dayjs.extend(customParseFormat)
      dayjs.extend(AdvancedFormat)
      setNewParvadaFechaInicio(dayjs(`${inputValue}`, "YYYYMMDD"))
    }, [inputValue])

    return (
      <div>
        {editing ? (
          <div>
            <DatePicker
              value={newParvadaFechaInicio}
              onChange={(e) => setInputValue(e?.format("YYYYMMDD") || "")}
              format={"DD/MM/YYYY"}
              allowClear={false}
            />
            <Button type="primary" onClick={handleSaveClick}>
              Grabar
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <span style={{ flex: 1 }}>{renderValue()}</span>
            <Button
              type="link"
              onClick={handleEditClick}
              icon={<EditOutlined />}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <Wrapper>
      <Row gutter={16}>
        <Col span={16}>
          <Contenido>
            <Button
              type="primary"
              onClick={() => setModalVisible(true)}
              icon={<PlusOutlined />}
            >
              Agregar Postura
            </Button>
            <Table
              dataSource={posturas}
              columns={columns}
              rowKey="id"
              rowSelection={{
                selectedRowKeys: selectedPosturaId ? [selectedPosturaId] : [],
                hideSelectAll: true,
                type: "radio",
                renderCell: () => <></>,
              }}
            />
            <Modal
              title="Agregar Postura"
              open={modalVisible}
              onOk={() => handleAddPostura()}
              onCancel={() => {
                setModalVisible(false)
                setNewPosturaName("")
              }}
            >
              <Form>
                <Form.Item label="Descripción">
                  <Input
                    value={newPosturaName}
                    onChange={(e) => setNewPosturaName(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Contenido>
        </Col>
        <Col span={8}>
          {showCasetas ? (
            <Contenido>
              <Button
                type="primary"
                onClick={() => setCasetasModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Agregar Caseta
              </Button>
              <Table
                dataSource={casetas}
                columns={casetasColumns}
                rowKey="id"
                pagination={false}
              />
              <Modal
                title="Agregar Caseta"
                open={casetasModalVisible}
                onOk={() => handleAddCaseta()}
                onCancel={() => {
                  setCasetasModalVisible(false)
                  setNewCasetaName("")
                  setNewCasetaNumCaseta(0)
                }}
              >
                <Form>
                  <Form.Item label="Numero Caseta">
                    <Input
                      value={newCasetaNumCaseta}
                      onChange={(e) =>
                        setNewCasetaNumCaseta(parseInt(e.target.value))
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Descripción">
                    <Input
                      value={newCasetaName}
                      onChange={(e) => setNewCasetaName(e.target.value)}
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </Contenido>
          ) : (
            <Contenido>
              <Button
                type="primary"
                onClick={() => setParvadasModalVisible(true)}
                icon={<PlusOutlined />}
              >
                Agregar Parvada
              </Button>
              <Table
                dataSource={parvadas}
                columns={parvadasColumns}
                rowKey="id"
                pagination={false}
              />
              <Modal
                title="Agregar Parvada"
                open={parvadasModalVisible}
                onOk={() => handleAddParvada()}
                onCancel={() => {
                  setParvadasModalVisible(false)
                  setNewParvadaLote("")
                  setNewParvadaFechaInicio(dayjs())
                  setNewParvadaActivo(false)
                }}
              >
                <Form>
                  <Form.Item label="Lote">
                    <Input
                      value={newParvadaLote}
                      onChange={(e) => setNewParvadaLote(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Fecha Inicio">
                    <DatePicker
                      value={newParvadaFechaInicio}
                      onChange={(e) => setNewParvadaFechaInicio(e || dayjs())}
                      format={"DD/MM/YYYY"}
                      allowClear={false}
                    />
                  </Form.Item>
                  <Form.Item label="Activo">
                    <Checkbox
                      value={newParvadaActivo}
                      onChange={(e) => setNewParvadaActivo(e.target.checked)}
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </Contenido>
          )}
        </Col>
      </Row>
    </Wrapper>
  )
}

export default PosturasScreen
