import { postWithToken } from "./apiService"

export const ParvadasActions = (token: string | null) => {
  return {
    getParvadas: async (id_postura: string) => {
      if (id_postura === "") return Promise.resolve([])
      try {
        const response = await postWithToken(`parvadas/listar`, token, {
          filtro: { id_postura: id_postura },
        })
        return response
      } catch (error) {
        console.error("Error fetching parvadas:", error)
        throw error
      }
    },
    addParvada: async (
      id_postura: string,
      lote: string,
      fechaInicio: string,
      activo: boolean
    ) => {
      try {
        const response = await postWithToken(`parvadas/agregar`, token, {
          parvadas: [
            {
              id_postura: id_postura,
              lote: parseInt(lote),
              fecha_inicio: parseInt(fechaInicio),
              activo: activo ? 1 : 0,
            },
          ],
        })
        return response
      } catch (error) {
        console.error("Error adding Parvada:", error)
        throw error
      }
    },
    deleteParvada: async (id: string) => {
      try {
        const response = await postWithToken(`parvadas/borrar`, token, {
          parvadas: [{ id: id }],
        })
        return response
      } catch (error) {
        console.error("Error deleting Parvada:", error)
        throw error
      }
    },
    updateParvada: async (
      id: string,
      id_postura: string,
      lote: number,
      fechaInicio: string,
      activo: boolean
    ) => {
      try {
        const response = await postWithToken(`parvadas/actualizar`, token, {
          parvadas: [
            {
              id: id,
              id_postura: id_postura,
              lote: lote,
              fecha_inicio: parseInt(fechaInicio),
              activo: activo ? 1 : 0,
            },
          ],
        })
        return response
      } catch (error) {
        console.error("Error updating Parvada:", error)
        throw error
      }
    },
  }
}
