import React, { useState, useEffect, useContext } from "react"
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  Switch,
  Space,
} from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { UsuariosActions, usuarioBackend } from "../Services/usuarios"
import { AuthContext } from "../Contexts/AuthContext"
import { Caseta, Postura } from "./PosturasCasetas"
import { PosturasActions } from "../Services/posturas"
import { CasetasActions } from "../Services/casetas"
import { Contenido, Wrapper } from "./Usuarios.styles"
import { useGeneralContext } from "../Contexts/GeneralContext"

interface permiso {
  id: string
  activo: boolean
}

interface User {
  id: string
  usuario: string
  nombre: string
  permisos: {
    posturas: permiso[]
    casetas: permiso[]
  }
}

const UsersScreen: React.FC = () => {
  const { token } = useContext(AuthContext)
  const { setTitulo } = useGeneralContext()

  const [users, setUsers] = useState<User[]>([])
  const [posturas, setPosturas] = useState<Postura[]>([])
  //   const [casetas, setCasetas] = useState<Caseta[]>([])

  const [modalVisible, setModalVisible] = useState(false)
  const [newUserEmail, setNewUserEmail] = useState("")
  const [newUserName, setNewUserName] = useState("")
  const [newUserPassword, setNewUserPassword] = useState("")
  const [newUserPermisos, setNewUserPermisos] = useState<permiso[]>([])

  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [pasturasPermission, setPasturasPermission] = useState<permiso[]>([])
  //   const [casetasPermission, setCasetasPermission] = useState<permiso[]>([])

  const usuariosActions = UsuariosActions(token)
  const posturasActions = PosturasActions(token)
  //   const casetasActions = CasetasActions(token)

  const dameListaPermisos = (usuario: User, posturas: Postura[]) => {
    if (posturas && posturas.length > 0) {
      const listaPermisos = posturas.map((postura) => ({
        id: postura.id,
        activo:
          usuario.permisos.posturas.find(
            (value: permiso) => value.id === postura.id
          )?.activo || false,
      }))
      return listaPermisos
    }
    return []
  }

  useEffect(() => {
    setTitulo("Usuarios")
    fetchPosturas()
    fetchUsers()
    // fetchCasetas()
  }, [])

  useEffect(() => {
    if (selectedUser) {
      setPasturasPermission(dameListaPermisos(selectedUser, posturas))
    }
  }, [selectedUser, posturas])

  const fetchPosturas = async () => {
    try {
      const data = await posturasActions.getPosturas()
      setPosturas(data)
    } catch (error) {
      console.error("Error fetching pasturas:", error)
    }
  }

  const fetchUsers = async () => {
    try {
      const data = await usuariosActions.getUsuarios()

      setSelectedUser(data[0])
      setUsers(data)
    } catch (error) {
      console.error("Error fetching users:", error)
    }
  }

  const handleAddUser = () => {
    setSelectedUser(null)
    const userPermisosNew = posturas.map((postura) => ({
      id: postura.id,
      activo: false,
    }))
    setNewUserPermisos(userPermisosNew)
    setModalVisible(true)
  }

  const handleModalCancel = () => {
    setModalVisible(false)
    setNewUserEmail("")
    setNewUserName("")
    setNewUserPassword("")
  }

  const handleModalOk = async () => {
    try {
      await usuariosActions.addUsuario({
        id: "",
        usuario: newUserEmail,
        password: newUserPassword,
        nombre: newUserName,
        permisos: JSON.stringify(newUserPermisos),
      })
      fetchUsers()
      setModalVisible(false)
      setNewUserEmail("")
      setNewUserName("")
      setNewUserPassword("")
      //   setPasturasPermission(false)
      //   setCasetasPermission(false)
    } catch (error) {
      console.error("Error adding user:", error)
    }
  }

  const handleSavePermissions = async () => {
    try {
      if (selectedUser) {
        await usuariosActions.updateUsuario({
          ...selectedUser,
          permisos: JSON.stringify(pasturasPermission),
        })
        fetchUsers()
      }
    } catch (error) {
      console.error("Error updating user:", error)
    }
  }

  const handleEditUser = (user: User) => {
    setSelectedUser(user)
    setNewUserEmail(user.usuario)
    setNewUserName(user.nombre)
    setPasturasPermission(user.permisos.posturas)
    // setCasetasPermission(user.permissions.casetas)
    setModalVisible(true)
  }

  const handleModalUpdate = async () => {
    if (selectedUser) {
      try {
        let request: usuarioBackend = {
          ...selectedUser,
          usuario: newUserEmail,
          nombre: newUserName,
          permisos: JSON.stringify(pasturasPermission),
        }

        if (newUserPassword !== "") {
          request = {
            ...request,
            password: newUserPassword,
          }
        }

        await usuariosActions.updateUsuario(request)

        fetchUsers()
        setModalVisible(false)
        setSelectedUser(null)
        // setPasturasPermission(false)
        // setCasetasPermission(false)
      } catch (error) {
        console.error("Error updating user:", error)
      }
    }
  }

  const handleDeleteUser = async (userId: string) => {
    // Implement your logic to delete a user with the given userId
    try {
      await usuariosActions.deleteUsuario(userId)
      fetchUsers()
      setModalVisible(false)
      setSelectedUser(null)
      //   setPasturasPermission(false)
      //   setCasetasPermission(false)
    } catch (error) {
      console.error("Error updating user:", error)
    }
  }

  const columns = [
    { title: "Email", dataIndex: "usuario", key: "usuario" },
    { title: "Nombre", dataIndex: "nombre", key: "nombre" },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: User) => (
        <>
          <Button type="link" onClick={() => handleEditUser(record)}>
            Editar
          </Button>
          <Button type="link" onClick={() => handleDeleteUser(record.id)}>
            Borrar
          </Button>
        </>
      ),
    },
    {
      title: "Permisos",
      dataIndex: "permisos",
      key: "permisos",
      render: (_: any, record: User) => (
        <Button
          type="link"
          onClick={() => {
            setSelectedUser(record)
            setPasturasPermission(dameListaPermisos(record, posturas))
          }}
        >
          Ver Permisos
        </Button>
      ),
    },
  ]

  return (
    <Wrapper>
      <Row gutter={16}>
        <Col span={18}>
          <Contenido>
            <Button
              type="primary"
              onClick={handleAddUser}
              icon={<PlusOutlined />}
            >
              Agregar Usuario
            </Button>
            <Table
              dataSource={users}
              columns={columns}
              rowKey="id"
              rowSelection={{
                selectedRowKeys: selectedUser?.id ? [selectedUser?.id] : [],
                hideSelectAll: true,
                type: "radio",
                renderCell: () => <></>,
              }}
            />
          </Contenido>
        </Col>
        <Col span={6}>
          <div style={{ marginTop: "80px" }}>
            <h3>Permisos {selectedUser?.usuario}</h3>
            <Row>
              <Col span={12}>
                <h4>Posturas</h4>
                {posturas?.map((pastura) => (
                  <Row>
                    <Space align="center">
                      <Switch
                        key={pastura.id}
                        checked={
                          pasturasPermission?.find(
                            (value: permiso) => value.id === pastura.id
                          )?.activo || false
                        }
                        onChange={(activo) => {
                          const newPermissions = pasturasPermission?.map(
                            (value: permiso) => {
                              if (value.id === pastura.id) {
                                return { ...value, activo: activo }
                              }
                              return value
                            }
                          )
                          setPasturasPermission(newPermissions)
                        }}
                      />
                      <p>{pastura.nombre}</p>
                    </Space>
                  </Row>
                ))}
              </Col>
            </Row>
            <Button type="primary" onClick={handleSavePermissions}>
              Grabar Permisos
            </Button>
          </div>
        </Col>
      </Row>

      <Modal
        title={selectedUser ? "Editar Usuario" : "Agregar Usuario"}
        open={modalVisible}
        onCancel={handleModalCancel}
        onOk={selectedUser ? handleModalUpdate : handleModalOk}
      >
        <Form>
          <Form.Item label="Email">
            <Input
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Nombre">
            <Input
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input.Password
              value={newUserPassword}
              onChange={(e) => setNewUserPassword(e.target.value)}
              placeholder={
                selectedUser ? "Dejar en blanco para no cambiar" : ""
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </Wrapper>
  )
}

export default UsersScreen
